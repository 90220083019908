import * as ActionTypes from "../common/actionTypes";

export const defaultAnswerState: AnswerState = {
    isLoading: false
}

// STATE
export interface AnswerState {
    query?: string;
    data?: Answer;
    isLoading: boolean;
    error?: Error;
}

export interface MetaData {
    follow_up_prompt: string;
}

export interface Answer {
    id?: number;
    question: string;
    confidenceScore: number;
    body: string;
    prompts: AnswerPrompt[];
    alternativeAnswers: AlternativeAnswer[];
    reportingId?: number;
    metadata: MetaData;
}

export interface AnswerPrompt {
    displayText: string;
    body: string;
    prompts: AnswerPrompt[];
    metadata: MetaData;
}

export interface AlternativeAnswer {
    id?: number;
    question: string;
}

// ACTIONS
interface RequestAnswer {
    type: typeof ActionTypes.RequestAnswer;
    question: string;
}

interface ReceiveAnswer {
    type: typeof ActionTypes.ReceiveAnswer;
    data: Answer;
}

interface HandleErrorAnswer {
    type: typeof ActionTypes.HandleErrorAnswer;
    error: Error;
}

interface ClearErrorAnswer {
    type: typeof ActionTypes.ClearErrorAnswer;
}

export type KnownAction =
    | RequestAnswer
    | ReceiveAnswer
    | HandleErrorAnswer
    | ClearErrorAnswer;
import * as ActionTypes from "../common/actionTypes";
import { Banner, KnownAction } from "../store/banner";
import { AppThunkAction } from "../store";
import * as BannerService from "../services/bannerService";

const requestReadActiveBanner = (): KnownAction => {
    return { type: ActionTypes.RequestReadActiveBanner };
};

const receiveReadActiveBanner = (data: Banner): KnownAction => {
    return { type: ActionTypes.ReceiveReadActiveBanner, data };
}

const requestUpsertActiveBanner = (banner: Omit<Banner, "id">): KnownAction => {
    return { type: ActionTypes.RequestUpsertActiveBanner, banner };
}

const receiveUpsertActiveBanner = (): KnownAction => {
    return { type: ActionTypes.ReceiveUpsertActiveBanner };
}

const handleError = (error: Error): KnownAction => {
    return { type: ActionTypes.HandleErrorBanner, error };
}

const clear = (): KnownAction => {
    return { type: ActionTypes.ClearErrorBanner };
}

export const bannerActionCreators = {
    readActiveBanner: (): AppThunkAction<KnownAction> => async dispatch => {
        dispatch(clear());
        dispatch(requestReadActiveBanner());

        try {
            const response = await BannerService.readActive();
            if (!response.ok) {
                throw new Error(response.rawBody);
            }

            dispatch(receiveReadActiveBanner(response.parsedBody));
        }
        catch (error) {
            dispatch(handleError(error as Error));
        }
    },
    
    upsertActiveBanner: (banner: Omit<Banner, "id">): AppThunkAction<KnownAction> => async dispatch => {
        dispatch(clear());
        dispatch(requestUpsertActiveBanner(banner));

        try {
            const response = await BannerService.upsertActive(banner);
            if (!response.ok) {
                throw new Error(response.rawBody);
            }

            dispatch(receiveUpsertActiveBanner());
        }
        catch (error) {
            dispatch(handleError(error as Error));
        }
    }
};

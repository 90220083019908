import { FC, useState } from "react";
import PositiveFeedbackFormSection from "./PositiveFeedbackFormSection";
import { Feedback } from "../../store/feedback";
import NegativeFeedbackFormSection from "./NegativeFeedbackFormSection";
import FeedbackPolaritySelector, { FeedbackPolarity } from "./FeedbackPolaritySelector";
import { getUserId } from "../../adalConfig";

interface FeedbackFormProps {
    onFeedbackPolarityButtonClick: (feedbackPolarity: FeedbackPolarity) => void;
    onFeedbackSubmit: (feedback: Omit<Feedback, 'id' | 'answer' | 'question' | 'navigationLink'>) => void;
    isLoading: boolean;
}

const FeedbackForm: FC<FeedbackFormProps> = (props) => {
    const [selectedFeedbackPolarity, setSelectedFeedbackPolarity] = useState<FeedbackPolarity | undefined>(undefined);
    
    const handlePositiveFeedbackButtonClick = () => {
        setSelectedFeedbackPolarity(FeedbackPolarity.Positive);
        props.onFeedbackPolarityButtonClick(FeedbackPolarity.Positive);
    }
    
    const handleNegativeFeedbackButtonClick = () => {
        setSelectedFeedbackPolarity(FeedbackPolarity.Negative);
        props.onFeedbackPolarityButtonClick(FeedbackPolarity.Negative);
    }
    
    const handleFeedbackSubmit = (feedback: Pick<Feedback, 'alternateAnswer' | 'alternateAnswerId' | 'feedbackTypeId' | 'userComments'>) => {
        if (selectedFeedbackPolarity === undefined) {
            return;
        }

        props.onFeedbackSubmit({ ...feedback, helpfulFlag: selectedFeedbackPolarity === FeedbackPolarity.Positive, userId: getUserId() });
    }
    
    return (
        <div id="feedback-div">
            <FeedbackPolaritySelector onPositiveFeedbackButtonClick={handlePositiveFeedbackButtonClick} onNegativeFeedbackButtonClick={handleNegativeFeedbackButtonClick} value={selectedFeedbackPolarity} />
            <form className="form" id="feedbackForm" name="form1">
                {selectedFeedbackPolarity === FeedbackPolarity.Positive && <PositiveFeedbackFormSection onSubmit={handleFeedbackSubmit} />}
                {selectedFeedbackPolarity === FeedbackPolarity.Negative && <NegativeFeedbackFormSection onSubmit={handleFeedbackSubmit} />}
            </form>
        </div>
    );
}

export default FeedbackForm;
export const get = <TValue>(key: string): TValue | undefined => {
    const rawValue = localStorage.getItem(key);
    
    let value: TValue | undefined = undefined;
    if (rawValue !== null) {
        try {
            value = JSON.parse(rawValue) as TValue;
        }
        catch (error) {
            console.warn(`Unable to deserialize local storage item at key ${key}. Defaulting to undefined.`, error);
        }
    }
    
    return value;
}

export const set = <TValue>(key: string, value: TValue | undefined) => {
    localStorage.setItem(key, JSON.stringify(value));
}

import { FC } from "react";
import Feedback from "../feedback/Feedback";
import QuestionManagement from "../questionManagement/QuestionManagement";

const Home: FC = () => {
    return (
        <>
            <QuestionManagement />
            <Feedback />
        </>
    );
}

export default Home;
import { Reducer } from "@reduxjs/toolkit";
import { Banner, BannerState, defaultBannerState, KnownAction } from "../store/banner";
import * as ActionTypes from "../common/actionTypes";
import * as LocalStorageHelper from "../common/localStorageHelper";
import * as BannerConstants from "../constants/bannerConstants";

const updateBannerData = (state: BannerState, data: Omit<Banner, "id">): Banner => {
    return {
        ...state.data,
        ...{ id: 1, ...data }
    };
}

export const bannerReducer: Reducer<BannerState, KnownAction> = (state, action) => {
    if (state === undefined) {
        return defaultBannerState;
    }
    
    switch (action.type) {
        case ActionTypes.RequestReadActiveBanner:
            return {
                ...state,
                isLoading: true
            };

        case ActionTypes.ReceiveReadActiveBanner:
            LocalStorageHelper.set(BannerConstants.bannerCacheKey, action.data);
            
            return {
                ...state,
                isLoading: false,
                data: action.data
            };
            
        case ActionTypes.RequestUpsertActiveBanner:
            LocalStorageHelper.set(BannerConstants.bannerCacheKey, action.banner);
            
            return {
                ...state,
                isLoading: true,
                data: updateBannerData(state, action.banner)
            };
            
        case ActionTypes.ReceiveUpsertActiveBanner:
            return {
                ...state,
                isLoading: false
            };

        case ActionTypes.HandleErrorBanner:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case ActionTypes.ClearErrorBanner:
            return {
                ...state,
                error: undefined
            }

        default:
            return { ...state };
    }
}
import React, { FC, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState, AppThunkDispatch } from "../../store";
import { Answer } from "../../store/answer";
import * as FeedbackStore from "../../store/feedback";
import { feedbackActionCreators } from "../../actions/feedbackActions";
import FeedbackForm from "./FeedbackForm";
import { FeedbackPolarity } from "./FeedbackPolaritySelector";
import * as FeedbackConstants from "../../constants/feedbackConstants";
import { getUserId } from "../../adalConfig";

type FeedbackProps =
    & ConnectedProps<typeof connector>
    & (
        {
            answer: Answer;
            query: string;
        } | { }
    );

const Feedback: FC<FeedbackProps> = (props) => {
    
    const { feedbackState } = props;
    const { createFeedback, updateFeedback } = props;
    
    const [isSubmitted, setIsSubmitted] = useState(false);
    
    const handleOnFeedbackPolarityButtonClick = (feedbackPolarity: FeedbackPolarity) => {
        if (feedbackPolarity === FeedbackPolarity.Positive) {
            if ('answer' in props) {
                createFeedback({
                    answer: props.answer.body,
                    answerId: props.answer.id,
                    helpfulFlag: true,
                    question: props.query,
                    navigationLink: window.location.href,
                    userId: getUserId()
                });
            }
            else {
                createFeedback({
                    answer: "",
                    helpfulFlag: true,
                    question: FeedbackConstants.generalFeedbackQuestion,
                    navigationLink: window.location.href,
                    userId: getUserId()
                });
            }
        }
    }
    
    const handleOnFeedbackSubmit = (feedback: Omit<FeedbackStore.Feedback, 'id' | 'question' | 'answer' | 'navigationLink'>) => {
        setIsSubmitted(true);
        
        if (feedback.helpfulFlag && feedbackState.feedback !== undefined) {
            updateFeedback({
                ...feedbackState.feedback,
                ...feedback
            });
        }
        else {
            if ('answer' in props) {
                createFeedback({
                    ...feedback,
                    answer: props.answer.body,
                    answerId: props.answer.id,
                    confidenceScore: props.answer.confidenceScore,
                    question: props.query,
                    navigationLink: window.location.href,
                    userId: getUserId()
                });
            }
            else {
                createFeedback({
                    ...feedback,
                    answer: "",
                    question: FeedbackConstants.generalFeedbackQuestion,
                    navigationLink: window.location.href,
                    userId: getUserId()
                });
            }
        }
    }
    
    return (
        <div id="feedbackElement">
            <section className="content align-center feedback-section">
                
                {isSubmitted ? (
                    <>
                        <h2>Thank you for your feedback.</h2>
                        <h3>If you have further questions on this topic, please contact your Account Executive for assistance.</h3>
                    </>
                ) : (
                    <>
                        <h2>UHM needs your feedback!</h2>
                        <p>Your feedback is very important to us. By providing your feedback, you contribute to helping not only your entire team, but our entire company. Please include your comments below. Your most recent steps within the Ask UHM tool will also be submitted with your comments to help us better serve you.</p>
                        
                        <div className="wrap">
                            <FeedbackForm isLoading={feedbackState.isLoading} onFeedbackSubmit={handleOnFeedbackSubmit} onFeedbackPolarityButtonClick={handleOnFeedbackPolarityButtonClick} />
                        </div>
                    </>
                )}
            </section>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState) => {
    const feedbackState = state.feedback;
    
    return { feedbackState };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    createFeedback: (feedback: Omit<FeedbackStore.Feedback, "id">) => {
        dispatch(feedbackActionCreators.create(feedback));
    },
    
    updateFeedback: (feedback: FeedbackStore.Feedback) => {
        dispatch(feedbackActionCreators.update(feedback));
    }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Feedback);

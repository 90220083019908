import { Reducer } from "@reduxjs/toolkit";
import { AnswerState, defaultAnswerState, KnownAction } from "../store/answer";
import * as ActionTypes from "../common/actionTypes";

export const answerReducer: Reducer<AnswerState, KnownAction> = (state, action) => {
    if (state === undefined) {
        return defaultAnswerState;
    }
    
    switch (action.type) {
        case ActionTypes.RequestAnswer:
            return {
                ...state,
                isLoading: true,
                query: action.question
            };
        
        case ActionTypes.ReceiveAnswer:
            return {
                ...state,
                isLoading: false,
                data: action.data
            };
        
        case ActionTypes.HandleErrorAnswer:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
            
        case ActionTypes.ClearErrorAnswer:
            return {
                ...state,
                error: undefined
            };
            
        default:
            return { ...state };
    }
}
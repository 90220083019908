import { FC, MouseEventHandler } from "react";
import { ThumbsUp as ThumbsUpIcon, ThumbsDown as ThumbsDownIcon } from "react-feather";

export enum FeedbackPolarity {
    Positive,
    Negative
}

interface FeedbackPolaritySelectorProps {
    onPositiveFeedbackButtonClick: () => void;
    onNegativeFeedbackButtonClick: () => void;
    value: FeedbackPolarity | undefined;
}

const FeedbackPolaritySelector: FC<FeedbackPolaritySelectorProps> = (props) => {
    const handlePositiveFeedbackButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        props.onPositiveFeedbackButtonClick();
    }
    
    const handleNegativeFeedbackButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        props.onNegativeFeedbackButtonClick();
    }
    
    return (
        <div className="row feedback-container">
            <div className="col half left-feedback-col">
                <button
                    type="button"
                    id="positive"
                    onClick={handlePositiveFeedbackButtonClick}
                    className={"button thumbs-up " + (props.value === FeedbackPolarity.Positive ? "selected" : "")}
                >
                    <ThumbsUpIcon className="thumbs-up-img" />
                    <h5>Great job!</h5>
                </button>
            </div>
            <div className="col half">
                <button
                    type="button"
                    id="negative"
                    onClick={handleNegativeFeedbackButtonClick}
                    className={"button thumbs-down " + (props.value === FeedbackPolarity.Negative ? "selected" : "")}
                >
                    <ThumbsDownIcon className="thumbs-down-img" />
                    <h5>Not so great</h5>
                </button>
            </div>
        </div>
    );
}

export default FeedbackPolaritySelector;
import { FC } from "react";

interface BounceLoaderProps {
}

const BounceLoader: FC<BounceLoaderProps> = (props) => {
    return (
        <div className="bounce-loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    );
}

export default BounceLoader;
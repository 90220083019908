export class FollowUpEncoding {

    public static DecodeFollowUpPrompt(prompt: string): string {
        if (prompt) {
            var asciiValue = 65;
            var asciiChar = 'A';
            while (asciiValue <= 90) {
                prompt = prompt.replaceAll('\\0x' + asciiValue, asciiChar);
                asciiValue++;
                asciiChar = String.fromCharCode(asciiValue);
            }
        }
        return prompt;
    }

}
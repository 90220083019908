import * as AnswerService from "../services/answerService";
import { Answer, KnownAction } from "../store/answer";
import * as ActionTypes from "../common/actionTypes";
import { AppThunkAction } from "../store";
import { TrackedQueryType } from "../common/TrackedQueryType";
import { getUserId } from "../adalConfig";

const request = (question: string): KnownAction => {
    return { type: ActionTypes.RequestAnswer, question };
}

const receive = (data: Answer): KnownAction => {
    return { type: ActionTypes.ReceiveAnswer, data };
}

const handleError = (error: Error): KnownAction => {
    return { type: ActionTypes.HandleErrorAnswer, error };
}

const clear = (): KnownAction => {
    return { type: ActionTypes.ClearErrorAnswer };
}

export const answerActionCreators = {
    readMostRelevantAnswer: (query: string, searchMethod?: TrackedQueryType, capture?: string): AppThunkAction<KnownAction> => async dispatch => {
        dispatch(clear());
        dispatch(request(query));
        
        try {
            const response = await AnswerService.readMostRelevant(query, searchMethod, capture, getUserId());
            if (!response.ok) {
                throw new Error(response.rawBody);
            }
            
            dispatch(receive(response.parsedBody));
        }
        catch (error) {
            dispatch(handleError(error as Error));
        }
    }
};
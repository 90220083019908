import { ChangeEventHandler, FC, MouseEventHandler, useEffect, useState } from "react";
import { Feedback } from "../../store/feedback";
import { ApplicationState, AppThunkDispatch } from "../../store";
import { feedbackTypeActionCreators } from "../../actions/feedbackTypeActions";
import Suspense from "../suspense/Suspense";
import { connect, ConnectedProps } from "react-redux";
import * as ArrayHelper from "../../common/arrayHelper";

interface NegativeFeedbackFormSectionProps extends ConnectedProps<typeof connector> {
    onSubmit: (feedback: Pick<Feedback, 'alternateAnswer' | 'alternateAnswerId' | 'feedbackTypeId' | 'userComments'>) => void;
}

const NegativeFeedbackFormSection: FC<NegativeFeedbackFormSectionProps> = (props) => {
    const [selectedFeedbackTypeId, setSelectedFeedbackTypeId] = useState(0);
    const [selectedAlternativeAnswerIndex, setSelectedAlternativeAnswerIndex] = useState(-1);
    const [comment, setComment] = useState("");
    const [validationError, setValidationError] = useState<string | null>(null);
    
    const { feedbackTypeState, answerState } = props;
    const { readFeedbackTypes } = props;
    
    useEffect(() => {
        if (!feedbackTypeState.isLoading && feedbackTypeState.data === undefined) {
            readFeedbackTypes();
        }
    }, [])
    
    useEffect(() => {
        const validate = () => {
            if (selectedFeedbackTypeId < 1) {
                setValidationError("Please select a feedback type.");
            }
            else if (comment.length < 4) {
                setValidationError("Please enter your comments about why you are experiencing issues.");
            }
            else if (comment.length > 2000) {
                setValidationError("Your comments must be less than 2000 characters.");
            }
            else {
                setValidationError(null);
            }
        }

        validate();
    }, [selectedFeedbackTypeId, comment, setValidationError]);
    
    const handleFeedbackTypeSelectChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setSelectedFeedbackTypeId(parseInt(event.currentTarget.value));
    }
    
    const handleAlternativeAnswerSelectChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setSelectedAlternativeAnswerIndex(parseInt(event.currentTarget.value));
    }

    const handleCommentChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        setComment(event.currentTarget.value);
    }

    const handleSubmitButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();

        if (validationError === null) {
            let feedback: Pick<Feedback, 'alternateAnswer' | 'alternateAnswerId' | 'feedbackTypeId' | 'userComments'> = {
                feedbackTypeId: selectedFeedbackTypeId,
                userComments: comment
            };
            
            if (selectedAlternativeAnswerIndex > -1 && !answerState.isLoading && answerState.data !== undefined) {
                const alternativeAnswer = answerState.data.alternativeAnswers[selectedAlternativeAnswerIndex];
                feedback.alternateAnswer = alternativeAnswer.question;
                feedback.alternateAnswerId = alternativeAnswer.id;
            }
            
            props.onSubmit(feedback);
        }
    }

    return (
        <Suspense isLoading={feedbackTypeState.isLoading} error={feedbackTypeState.error} errorDisplayMessage="There was a problem loading feedback form. Please try again later." data={feedbackTypeState.data}>
            {(feedbackTypes) => (
                <>
                    <div>
                        <div className="select">
                            <label htmlFor="feedbackType">Type of Issue:</label>
                            <select id="feedbackType" onChange={handleFeedbackTypeSelectChange} value={selectedFeedbackTypeId}>
                                <option value={0} disabled hidden>Please Select...</option>
                                {feedbackTypes.map((feedbackType, index) => <option key={index} value={feedbackType.id}>{feedbackType.typeDescription}</option>)}
                            </select>
                        </div>
                    </div>

                    {!answerState.isLoading && !ArrayHelper.isEmpty(answerState.data?.alternativeAnswers) && (
                        <div>
                            <div className="select">
                                <label htmlFor="alternativeAnswerSelect">Do any of these questions make more sense for your query?</label>
                                <select id="alternativeAnswerSelect" onChange={handleAlternativeAnswerSelectChange} value={selectedAlternativeAnswerIndex}>
                                    <option value={-1}>No</option>
                                    {answerState.data!.alternativeAnswers.map((alternativeAnswer, index) => <option key={index} value={index}>{alternativeAnswer.question}</option>)}
                                </select>
                            </div>
                        </div>
                    )}
                    
                    <label htmlFor="feedback-comment">
                        Additional Comments:<span className="requiredInputs">*</span>
                    </label>

                    <textarea
                        name="comment"
                        className=" feedback-input"
                        id="feedback-comment"
                        placeholder="Enter feedback here."
                        onChange={handleCommentChange}
                    />
                    {validationError && <p className="errorMessage">{validationError}</p>}

                    <button type="submit" id="feedback-button" onClick={handleSubmitButtonClick} disabled={validationError !== null}>
                        Submit Feedback
                    </button>
                </>
            )}
        </Suspense>

    );
}

const mapStateToProps = (state: ApplicationState) => {
    const feedbackTypeState = state.feedbackType;
    const answerState = state.answer;
    
    return { feedbackTypeState, answerState };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    readFeedbackTypes: () => {
        dispatch(feedbackTypeActionCreators.readFeedbackTypes());
    }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(NegativeFeedbackFormSection);

import { Feedback, KnownAction } from "../store/feedback";
import * as ActionTypes from "../common/actionTypes";
import { AppThunkAction } from "../store";
import * as FeedbackService from "../services/feedbackService";

const requestCreate = (feedback: Omit<Feedback, "id">): KnownAction => {
    return { type: ActionTypes.RequestCreateFeedback, feedback };
}

const receiveCreate = (feedback: Feedback): KnownAction => {
    return { type: ActionTypes.ReceiveCreateFeedback, feedback };
}

const requestUpdate = (feedback: Feedback): KnownAction => {
    return { type: ActionTypes.RequestUpdateFeedback, feedback };
}

const receiveUpdate = (): KnownAction => {
    return { type: ActionTypes.ReceiveUpdateFeedback };
}

const handleError = (error: Error): KnownAction => {
    return { type: ActionTypes.HandleErrorFeedback, error };
}

const clear = (): KnownAction => {
    return { type: ActionTypes.ClearErrorFeedback };
}

export const feedbackActionCreators = {
    create: (feedback: Omit<Feedback, "id">): AppThunkAction<KnownAction> => async dispatch => {
        dispatch(clear());
        dispatch(requestCreate(feedback));
        
        try {
            const response = await FeedbackService.createAsync(feedback);
            if (!response.ok) {
                throw new Error(response.rawBody);
            }
            
            const createdFeedback: Feedback = {
                ...feedback,
                id: response.parsedBody
            }
            
            dispatch(receiveCreate(createdFeedback));
        }
        catch (error) {
            dispatch(handleError(error as Error));
        }
    },
    
    update: (feedback: Feedback): AppThunkAction<KnownAction> => async dispatch => {
        dispatch(clear());
        dispatch(requestUpdate(feedback));
        
        try {
            const response = await FeedbackService.updateAsync(feedback);
            if (!response.ok) {
                throw new Error(response.rawBody);
            }
            
            dispatch(receiveUpdate());
        }
        catch (error) {
            dispatch(handleError(error as Error));
        }
    }
};
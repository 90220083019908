import { FC } from "react";

export const Footer: FC = () => {
    const today = new Date();

    return (
        <div className="alice-footer">
            <img className="uhm-logo" src={process.env.PUBLIC_URL + "/images/UHM.svg"} alt="Uhm footer logo" />
            <p>&copy;{today.getFullYear()} Union Home Mortgage</p>
        </div>
    );
}

export default Footer;
import { defaultSearchState, KnownAction, SearchState } from "../store/search";
import { Reducer } from "@reduxjs/toolkit";
import * as ActionTypes from "../common/actionTypes";

export const searchReducer: Reducer<SearchState, KnownAction> = (state, action) => {
    if (state === undefined) {
        return defaultSearchState;
    }
    
    switch (action.type) {
        case ActionTypes.RequestSearch:
            return {
                ...state,
                isLoading: true
            };

        case ActionTypes.ReceiveSearch:
            return {
                ...state,
                isLoading: false,
                data: action.data
            };

        case ActionTypes.HandleErrorSearch:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case ActionTypes.ClearErrorSearch:
            return {
                ...state,
                error: undefined
            };

        default:
            return {...state};
    }
}

import { FC, useState } from "react";
import ReactMarkdown from "react-markdown";
import { AnswerPrompt } from "../../store/answer";
import { AnswerPromptDropdown } from "./AnswerPromptDropdown";
import remarkGfm from "remark-gfm";
import { FollowUpEncoding } from "../../common/followUpEncoding"

interface AnswerContentProps {
    markdownBody: string;
    prompts: AnswerPrompt[];
    followUp: string | undefined;
    reportingId?: number;
}

export const AnswerContent: FC<AnswerContentProps> = (props) => {
    const [selectedPromptIndex, setSelectedPromptIndex] = useState<number | undefined>();

    const handleAnswerPromptListSelect = (selectionIndex: number) => {
        setSelectedPromptIndex(selectionIndex);
    }

    const selectedPrompt = selectedPromptIndex !== undefined ? props.prompts[selectedPromptIndex] : undefined;

    return (
        <div className="answer">
            <div className="answer-content" dangerouslySetInnerHTML={{ __html: props.markdownBody }}></div>
            {props.followUp} {props.prompts.length > 0 && <AnswerPromptDropdown
                prompts={props.prompts}
                onSelect={handleAnswerPromptListSelect}
                value={selectedPromptIndex}
                reportingId={props.reportingId} />}
            {selectedPrompt && <AnswerContent markdownBody={selectedPrompt.body}
                followUp={FollowUpEncoding.DecodeFollowUpPrompt(selectedPrompt.metadata?.follow_up_prompt)}
                prompts={selectedPrompt.prompts}
                reportingId={props.reportingId} />}
        </div>
    );
}
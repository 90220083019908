import { defaultQuestionManagementState, KnownAction, QuestionManagementData, QuestionManagementEntry, QuestionManagementState } from "../store/questionManagement";
import { Reducer } from "@reduxjs/toolkit";
import * as ActionTypes from "../common/actionTypes";
import { QuestionType } from "../common/questionType";

const addQuestionManagementEntry = (state: QuestionManagementState, questionManagementEntry: Omit<QuestionManagementEntry, 'id'>): QuestionManagementData => {
    const newQuestionManagementData = {
        faqs: [...(state.data?.faqs ?? [])],
        raqs: [...(state.data?.raqs ?? [])]
    };
    
    switch (questionManagementEntry.questionType) {
        case QuestionType.Faq:
            newQuestionManagementData.faqs = [...newQuestionManagementData.faqs, { id: -1, ...questionManagementEntry }];
            break;
        
        case QuestionType.Raq:
            newQuestionManagementData.raqs = [...newQuestionManagementData.raqs, { id: -1, ...questionManagementEntry }];
            break;
    }
    
    return { ...state.data, ...newQuestionManagementData };
}

const removeQuestionManagementEntry = (state: QuestionManagementState, questionManagementEntryId: number): QuestionManagementData => {
    const newQuestionManagementData = {
        faqs: state.data?.faqs.filter(e => e.id !== questionManagementEntryId) ?? [],
        raqs: state.data?.raqs.filter(e => e.id !== questionManagementEntryId) ?? []
    }
    
    return { ...state.data, ...newQuestionManagementData };
}

export const questionManagementReducer: Reducer<QuestionManagementState, KnownAction> = (state, action) => {
    if (state === undefined) {
        return defaultQuestionManagementState;
    }
    
    switch (action.type) {
        case ActionTypes.RequestCreateQuestionManagement:
            return {
                ...state,
                isLoading: true
            };
            
        case ActionTypes.ReceiveCreateQuestionManagement:
            return {
                ...state,
                isLoading: false,
                data: addQuestionManagementEntry(state, action.data),
            }
            
        case ActionTypes.RequestReadCollectionQuestionManagement:
            return {
                ...state,
                isLoading: true
            };
            
        case ActionTypes.ReceiveReadCollectionQuestionManagement:
            return {
                ...state,
                isLoading: false,
                data: action.data
            };
            
        case ActionTypes.RequestDeleteQuestionManagement:
            return {
                ...state,
                isLoading: true,
                data: removeQuestionManagementEntry(state, action.questionManagementEntryId)
            }
            
        case ActionTypes.ReceiveDeleteQuestionManagement:
            return {
                ...state,
                isLoading: false
            }
            
        case ActionTypes.HandleErrorQuestionManagement:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
            
        case ActionTypes.ClearErrorQuestionManagement:
            return {
                ...state,
                error: undefined
            }
            
        default:
            return { ...state };
    }
}
import { FC, useEffect, useState } from "react";
import Suspense from "../suspense/Suspense";
import { connect, ConnectedProps } from "react-redux";
import { questionManagementActionCreators } from "../../actions/questionManagementActions";
import { ApplicationState, AppThunkDispatch } from "../../store";
import QuestionManagementList from "./QuestionManagementList";
import { QuestionManagementEntry } from "../../store/questionManagement";
import DeleteQuestionManagementEntryModal from "./DeleteQuestionManagementEntryModal";

interface QuestionManagementProps extends ConnectedProps<typeof connector> {
}

const QuestionManagement: FC<QuestionManagementProps> = (props) => {
    const [questionManagementEntryToDelete, setQuestionManagementEntryToDelete] = useState<QuestionManagementEntry | null>(null);
    
    const { questionManagementState } = props;
    const { readQuestionManagementData } = props;

    useEffect(() => {
        if ((questionManagementState.data === undefined || questionManagementState.error) && !questionManagementState.isLoading) {
            readQuestionManagementData();
        }
    }, []);
    
    const handleQuestionManagementListDelete = (questionManagementEntry: QuestionManagementEntry) => {
        setQuestionManagementEntryToDelete(questionManagementEntry);
    }
    
    const handleModalClose = () => {
        setQuestionManagementEntryToDelete(null);
    }
    
    const isDeleteModalVisible = questionManagementEntryToDelete !== null;

    return (
        <>
            <DeleteQuestionManagementEntryModal isVisible={isDeleteModalVisible} questionManagementEntry={questionManagementEntryToDelete} onClose={handleModalClose} />
        
            <div className="question-list-container">
                
                <div className="col half questions-list left-questions">
                    <h2>Recently Added Questions</h2>
                    <Suspense isLoading={questionManagementState.isLoading && !isDeleteModalVisible} error={questionManagementState.error} errorDisplayMessage={"There was a problem reading RAQs. Please try again later"} data={questionManagementState.data}>
                        {(questionManagementData) => (
                            <QuestionManagementList questionManagementEntries={questionManagementData.raqs} onDelete={handleQuestionManagementListDelete} />
                        )}
                    </Suspense>
                </div>
                
                <div className="col half questions-list right-questions">
                    <h2>FAQs of the Week</h2>
                    <Suspense isLoading={questionManagementState.isLoading && !isDeleteModalVisible} error={questionManagementState.error} errorDisplayMessage={"There was a problem reading FAQs. Please try again later."} data={questionManagementState.data}>
                        {(questionManagementData) => (
                            <QuestionManagementList questionManagementEntries={questionManagementData.faqs} onDelete={handleQuestionManagementListDelete} />
                        )}
                    </Suspense>
                </div>
                
            </div>
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => {
    const questionManagementState = state.questionManagement;

    return { questionManagementState };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    readQuestionManagementData: () => {
        dispatch(questionManagementActionCreators.readQuestionManagementData());
    }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(QuestionManagement);
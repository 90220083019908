import { useNavigate } from "react-router-dom";

export const useNavigateTo = () => {
    const navigate = useNavigate();
    
    return (path: string, searchParams?: string[][] | Record<string, string> | string | URLSearchParams) => {
        let url = path;

        if (searchParams) {
            const searchParamsObject = searchParams instanceof URLSearchParams ? searchParams : new URLSearchParams(searchParams);
            url += `?${searchParamsObject.toString()}`;
        }
        
        navigate(url);
    }
}
import React, { FC, MouseEventHandler } from 'react';
import { HelpCircle as HelpCircleIcon, Home as HomeIcon, Send as SendIcon, ThumbsUp as ThumbsUpIcon } from "react-feather";
import { Link, useSearchParams } from "react-router-dom";
import * as NavigationHelper from "../common/navigationHelper";

interface HeaderProps {
    isScrolledToTop: boolean;
    homeClicked: any;
}

const Navigation: FC<HeaderProps> = (props) => {
    const [searchParams] = useSearchParams();
    
    const query = searchParams.get("query");
    
    const scrollToFeedback: MouseEventHandler<HTMLAnchorElement> = (event) => {
        // To keep the feedback button keyboard accessible, it must have an href attribute.
        // However, we don't want the browser to navigate to the feedback page, so we prevent the default behavior.
        event.preventDefault();
        
        const feedbackElement = document.getElementById("feedbackElement");
        feedbackElement?.scrollIntoView({ behavior: "smooth" });
    }

    const goHome = () => {
        props.homeClicked();
        return "/";
    }

    const mailToLink = () => {
        let mailTo: string;
        
        if (query !== null) {
            mailTo = NavigationHelper.createMailToLink(`${query} from Ask UHM`, 
                "View the answer to this question and more here:\n"
                + "\n"
                + window.location.href
                + "\n"
                + "\n"
                + "\n"
                + "*This email is for internal use only*"
                + "\n"
                + "\n"
                + "\n"
                + "\n");
        }
        else {
            mailTo = NavigationHelper.createMailToLink("Check out Ask UHM",
                "UHM has the answers to your retail lending guide questions and more here:"
                + "\n"
                + "\n"
                + window.location.href
                + "\n"
                + "\n"
                + "\n"
                + "*This email is for internal use only*"
                + "\n"
                + "\n"
                + "\n"
                + "\n");
        }
        
        return mailTo;
    }
    
    return (
        <div className={`alice-header ${props.isScrolledToTop ? "static-navbar" : "scrolling-navbar"}`}>
            <div role="banner" className="header-uhm" id="alice-header-nav">
                <div className="wrap extra-large">
                    <nav className="nav-uhm alice-icons" role="menu">
                        <ul className="left-list">
                            <li>
                                <Link onClick={goHome} className="menuitem" to="/">
                                    <HomeIcon className="icon-button" />
                                    <span className="icon-button-text">Home</span>
                                </Link>
                            </li>
                            <li>
                                <Link className="menuitem" to="/help">
                                    <HelpCircleIcon className="icon-button" />
                                    <span className="icon-button-text">Help</span>
                                </Link>
                            </li>
                        </ul>
                        <div id="ask-alice-logo-purple" style={{ background: "center / cover no-repeat url('" + process.env.PUBLIC_URL + "images/Ask-UHM-Logo.svg')" }}></div>
                        <ul className="right-list">
                            <li>
                                <a href={mailToLink()}>
                                    <SendIcon className="icon-button" />
                                    <span className="icon-button-text">Email</span>
                                </a>
                            </li>
                            <li>
                                <a href="/" onClick={scrollToFeedback}>
                                    <ThumbsUpIcon className="icon-button" />
                                    <span className="icon-button-text">Feedback</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Navigation;

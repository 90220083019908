import { QuestionManagementEntry } from "../../store/questionManagement";
import { FC } from "react";
import { Trash2 as Trash2Icon } from "react-feather";
import { useNavigateTo } from "../../hooks/useNavigateTo";
import { TrackedQueryType } from "../../common/TrackedQueryType";

interface QuestionManagementListItemProps {
    questionManagementEntry: QuestionManagementEntry;
    allowDeletion: boolean;
    onDelete: (questionManagementEntry: QuestionManagementEntry) => void;
}

const QuestionManagementListItem: FC<QuestionManagementListItemProps> = (props) => {
    const navigateTo = useNavigateTo();

    const { questionManagementEntry, allowDeletion, onDelete } = props;

    const navigateToAnswer = (questionManagementEntry: QuestionManagementEntry) => {
        navigateTo("/answer", { query: questionManagementEntry.questionText, searchMethod: TrackedQueryType.FAQRAQ, capturedSearchText: "N/A"});
    }

    return (
        <li>
            <a onClick={() => navigateToAnswer(questionManagementEntry)}>{questionManagementEntry.questionText}</a>
            {allowDeletion && <Trash2Icon className={"delete-icon"} onClick={() => onDelete(questionManagementEntry)} />}
        </li>
    );
}

export default QuestionManagementListItem;
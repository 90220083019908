import { useEffect, useRef } from "react";

/**
 * Executes onOutsideElementClick when a click event occurs on an element that is not a child of the element with the ref
 * @param onNonChildElementClick
 */
export const useNonChildElementClick = <THTMLElement extends HTMLElement>(onNonChildElementClick: (event: MouseEvent) => void) => {
    const elementRef = useRef<THTMLElement>(null);

    const handleDocumentClick = (event: MouseEvent) => {
        if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
            onNonChildElementClick(event);
        }
    }
    
    useEffect(() => {
        document.addEventListener("click", handleDocumentClick)

        return () => document.removeEventListener("click", handleDocumentClick);
    }, []);
    
    return elementRef;
}
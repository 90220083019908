import { FC, MouseEventHandler, useState } from "react";
import Modal from "../Modal";
import { QuestionType } from "../../common/questionType";
import { ApplicationState, AppThunkDispatch } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import Suspense from "../suspense/Suspense";
import * as QuestionManagementHelper from "../../common/questionManagementHelper";
import { QuestionManagementEntry } from "../../store/questionManagement";
import { questionManagementActionCreators } from "../../actions/questionManagementActions";

interface AddQuestionManagementEntryModalProps extends ConnectedProps<typeof connector> {
    isVisible: boolean;
    questionText: string;
    questionType: QuestionType | null;
    onClose: () => void;
}

const AddQuestionManagementEntryModal: FC<AddQuestionManagementEntryModalProps> = (props) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [validationError, setValidationError] = useState<string | null>(null);
    
    const { createQuestionManagementEntry } = props;
    const { questionManagementState } = props;
    
    const handleCancelButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        props.onClose();
    }
    
    const handleAddButtonClick: MouseEventHandler<HTMLButtonElement> = async () => {
        setIsSubmitted(true);
        
        if (props.questionType !== null && questionManagementState.data) {
            if (QuestionManagementHelper.questionManagementListExceedsMaximumLength(questionManagementState.data, props.questionType)) {
                setValidationError(`Please delete older ${QuestionManagementHelper.getQuestionTypeName(props.questionType)}s before adding additional ones.`);
            }
            else if (QuestionManagementHelper.questionManagementItemAlreadyExists(questionManagementState.data, props.questionText)) {
                setValidationError("This question is already in either the RAQ or FAQ list.");
            }
            else {
                createQuestionManagementEntry({ questionText: props.questionText, questionType: props.questionType });
            }
        }
        else {
            setValidationError("An error occurred. Please try again.");
        }
    }
    
    const handleCloseButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        props.onClose();
        setIsSubmitted(false);
        setValidationError(null);
    }
    
    const getMessage = () => {
        let message = "";

        if (props.questionType === QuestionType.Raq) {
            message = "Are you sure you want to add this question to the RAQ section?";
        }
        else if (props.questionType === QuestionType.Faq) {
            message = "Are you sure you want to add this question to the FAQ section?";
        }

        return message;
    }
    
    const getSubmittedMessage = () => {
        return "The question has been successfully added";
    }
    
    const getErrorMessage = () => {
        let message = "An unknown error occurred.";

        if (props.questionType === QuestionType.Raq) {
            message = "An error occurred while adding this question to the RAQ section.";
        }
        else if (props.questionType === QuestionType.Faq) {
            message = "An error occurred while adding this question to the FAQ section.";
        }

        return message;
    }
    
    return (
        <Modal
            isVisible={props.isVisible}
            modalStyle="popup-small"
        >
            <Suspense isLoading={questionManagementState.isLoading}>
                {!isSubmitted ? (
                    <>
                        <h2>{getMessage()}</h2>

                        <div className="drawer-split">
                            <button className="cancel" onClick={handleCancelButtonClick}>
                                <i className="fa fa-times" />  Cancel
                            </button>
                            <button className="save" onClick={handleAddButtonClick}>
                                <i className="fa fa-plus"/>  Add
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <h2>{validationError !== null ? validationError : (questionManagementState.error ? getErrorMessage() : getSubmittedMessage())}</h2>
                        
                        <div className="drawer-split">
                            <button className="cancel" onClick={handleCloseButtonClick}>
                                <i className="fa fa-times" />  Close
                            </button>
                        </div>
                    </>
                )}
            </Suspense>
        </Modal>
    );
}

const mapStateToProps = (applicationState: ApplicationState) => {
    const questionManagementState = applicationState.questionManagement;
    
    return { questionManagementState };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    createQuestionManagementEntry: (questionManagementEntry: Omit<QuestionManagementEntry, 'id'>) => {
        dispatch(questionManagementActionCreators.createQuestionManagementEntry(questionManagementEntry));
    }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(AddQuestionManagementEntryModal);
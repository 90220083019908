const imageFiles = [
    'image/jpeg',
    'image/png',
    "image/svg+xml",
];

const videoFiles = [
    'video/mp4',
];

export const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    
    fileReader.onload = () => {
        let base64 = "";
        
        if (fileReader.result !== null) {
            base64 = readBase64FromDataUrl(fileReader.result.toString());
        }
        
        return resolve(base64);
    };
    fileReader.onerror = (error) => reject(error);
    
    fileReader.readAsDataURL(file);
});

export const readBase64FromDataUrl = (dataUrl: string) => {
    const matches = dataUrl.match(/data:.*;base64,(.*)/);
    
    let base64 = "";
    if (matches && matches.length >= 2) {
        base64 = matches[1];
    }
    
    return base64;
}

export const buildDataUrl = (base64Content: string, type: string) => {
    return `data:${type};base64,${base64Content}`;
}

export const isImageFile = (fileType: string) => {
    return imageFiles.includes(fileType);
}

export const isVideoFile = (fileType: string) => {
    return videoFiles.includes(fileType);
}

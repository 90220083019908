import { FC, MouseEventHandler, PropsWithChildren } from "react";

type ModalStyle = "popup" | "popup-small" | "drawer";

interface ModalProps extends PropsWithChildren {
    hasCloseButton?: boolean,
    onClose?: () => void
    isVisible: boolean;
    modalStyle?: ModalStyle;
}

const Modal: FC<ModalProps> = ({ isVisible, modalStyle = "popup", ...props }) => {
    
    const handleDrawerCloseButtonClick: MouseEventHandler<HTMLDivElement> = () => {
        if (props.onClose) {
            props.onClose();
        }
    }
    
    return (
        <section className={`background-drawer ${isVisible ? "show-drawer" : "hide-drawer"}`}>
            <div className={modalStyle}>
                <div className="drawer-container">
                    <div className="drawer-content">
                        {props.hasCloseButton && <div className="close-drawer" onClick={handleDrawerCloseButtonClick}><i className="fa fa-times" /></div>}
                        
                        {props.children}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Modal;
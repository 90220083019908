import { QuestionType } from "../common/questionType";
import * as ActionTypes from "../common/actionTypes";

export const defaultQuestionManagementState: QuestionManagementState = {
    isLoading: false
};

// STATE
export interface QuestionManagementState {
    data?: QuestionManagementData;
    isLoading: boolean;
    error?: Error;
}

export interface QuestionManagementData {
    faqs: QuestionManagementEntry[];
    raqs: QuestionManagementEntry[];
}

export interface QuestionManagementEntry {
    id: number;
    questionText: string;
    questionType: QuestionType;
}

// ACTIONS
interface RequestCreateQuestionManagement {
    type: typeof ActionTypes.RequestCreateQuestionManagement;
    questionManagementEntry: Omit<QuestionManagementEntry, 'id'>;
}

interface ReceiveCreateQuestionManagement {
    type: typeof ActionTypes.ReceiveCreateQuestionManagement;
    data: QuestionManagementEntry;
}

interface RequestReadCollectionQuestionManagement {
    type: typeof ActionTypes.RequestReadCollectionQuestionManagement
}

interface ReceiveReadCollectionQuestionManagement {
    type: typeof ActionTypes.ReceiveReadCollectionQuestionManagement;
    data: QuestionManagementData;
}

interface RequestDeleteQuestionManagement {
    type: typeof ActionTypes.RequestDeleteQuestionManagement;
    questionManagementEntryId: number;
}

interface ReceiveDeleteQuestionManagement {
    type: typeof ActionTypes.ReceiveDeleteQuestionManagement;
}

interface HandleErrorQuestionManagement {
    type: typeof ActionTypes.HandleErrorQuestionManagement
    error: Error;
}

interface ClearErrorQuestionManagement {
    type: typeof ActionTypes.ClearErrorQuestionManagement;
}

export type KnownAction =
    | RequestCreateQuestionManagement
    | ReceiveCreateQuestionManagement
    | RequestReadCollectionQuestionManagement
    | ReceiveReadCollectionQuestionManagement
    | RequestDeleteQuestionManagement
    | ReceiveDeleteQuestionManagement
    | HandleErrorQuestionManagement
    | ClearErrorQuestionManagement;
import * as ActionTypes from "../common/actionTypes";

export const defaultFeedbackTypeState: FeedbackTypeState = {
    isLoading: false
}

// STATE
export interface FeedbackTypeState {
    data?: FeedbackType[];
    isLoading: boolean;
    error?: Error;
}

export interface FeedbackType {
    id: number;
    typeDescription: string;
}

// ACTIONS
interface RequestFeedbackTypes {
    type: typeof ActionTypes.RequestFeedbackTypes;
}

interface ReceiveFeedbackTypes {
    type: typeof ActionTypes.ReceiveFeedbackTypes;
    feedbackTypes: FeedbackType[];
}

interface HandleErrorFeedbackTypes {
    type: typeof ActionTypes.HandleErrorFeedbackTypes;
    error: Error;
}

interface ClearErrorFeedbackTypes {
    type: typeof ActionTypes.ClearErrorFeedbackTypes;
}

export type KnownAction =
    | RequestFeedbackTypes
    | ReceiveFeedbackTypes
    | HandleErrorFeedbackTypes
    | ClearErrorFeedbackTypes;
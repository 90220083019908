import { Reducer } from "@reduxjs/toolkit";
import { defaultFeedbackTypeState, FeedbackTypeState, KnownAction } from "../store/feedbackType";
import * as ActionTypes from "../common/actionTypes";

export const feedbackTypeReducer: Reducer<FeedbackTypeState, KnownAction> = (state, action) => {
    if (state === undefined) {
        return defaultFeedbackTypeState;
    }
    
    switch (action.type) {
        case ActionTypes.RequestFeedbackTypes:
            return {
                ...state,
                isLoading: true
            };
            
        case ActionTypes.ReceiveFeedbackTypes:
            return {
                ...state,
                isLoading: false,
                data: action.feedbackTypes
            };
            
        case ActionTypes.HandleErrorFeedbackTypes:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
            
        case ActionTypes.ClearErrorFeedbackTypes:
            return {
                ...state,
                error: undefined
            };
            
        default:
            return { ...state };
    }
}
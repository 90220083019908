import React, { FC } from 'react';
import Layout from "./components/Layout";
import { Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import Help from "./components/Help";
import Answer from "./components/answer/Answer";

export const App: FC = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/answer" element={<Answer />} />
                <Route path='/help' element={<Help />} />
            </Routes>
        </Layout>
    );
}

export default App;

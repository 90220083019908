import { ChangeEventHandler, FC, FocusEventHandler, KeyboardEventHandler, MouseEventHandler, useRef } from "react";
import { Search as SearchIcon, X as XIcon } from "react-feather";

interface SearchInputProps {
    value: string;
    onChange: (value: string) => void;
    onSubmit: () => void;
    onClear: () => void;
    onFocus: (value: string) => void;
    onGoToNextSuggestion: () => void;
    onGoToPreviousSuggestion: () => void;
}

const SearchInput: FC<SearchInputProps> = (props) => {
    const inputElementRef = useRef<HTMLInputElement>(null);
    
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        props.onChange(event.currentTarget.value);
    }
    
    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        switch (event.key) {
            case "ArrowDown":
                props.onGoToNextSuggestion();
                break;
                
            case "ArrowUp":
                props.onGoToPreviousSuggestion();
                break;
        }
    }
    
    const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
        props.onFocus(event.currentTarget.value);
    }
    
    const handleSearchButtonClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        if (inputElementRef.current) {
            inputElementRef.current.blur();
        }
        
        props.onSubmit();
    }
    
    const handleClearButtonClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        props.onClear();
    }
    
    return (
        <div className="input-wrapper">
            <label htmlFor="searchSuggestions" className="visuallyhidden">Ask a TPO lending guide question...</label>
            <input
                ref={inputElementRef}
                role="combobox"
                autoComplete="off"
                type="text"
                placeholder='Ask a TPO lending guide question...'
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                value={props.value}
                id="searchSuggestions"
            />
            
            <div className="search-buttons">
                {props.value.length > 0 && (
                    <a className="button search-button" onClick={handleClearButtonClick}>
                        <XIcon />
                        <span className="visuallyhidden">Clear</span>
                    </a>
                )}
            </div>
        </div>
    );
}

export default SearchInput;

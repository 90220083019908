import React, { FC } from 'react';

const Help: FC = () => {
    return (
        <div className="col full align-center alice-help">
            <section className="hero help-section">
                <div className="wrap">
                    <div className="wrap" id="help-wrap">
                        <video loop controls playsInline={true} className="logo-video">
                            <source src={"images/Ask-UHM-Demo.mp4"} type="video/mp4" />
                        </video>
                    </div>
                    <h2 className="align-left">Tips for Using Ask UHM</h2>
                    <ul>
                        <li>
                            Type a full question to get better results. While typing, select the suggested question that best matches yours.
                        </li>
                        <li>
                            Try typing two or more keywords, phrases or terms describing the nature of your inquiry if you aren't sure how to ask the question.
                        </li>
                        <li>
                            Make sure you are spelling the keywords correctly.
                            </li>
                        <li>
                            Do not use quotation marks or special characters when typing your question or phrase.
                            </li>
                        <li>
                            To ask a new question, simply click the Union Home Mortgage, or Ask UHM logo or erase your current question.
                        </li>
                        <li>
                            Spell out words to help refine your search. For example, type "Social Security Number" instead of "SSN" or "SSA". State abbreviations in capital letters will work fine.
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    );
}

export default Help;

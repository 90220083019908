import { FC, MouseEventHandler, useState } from "react";
import Modal from "../Modal";
import { ApplicationState, AppThunkDispatch } from "../../store";
import { questionManagementActionCreators } from "../../actions/questionManagementActions";
import { QuestionManagementEntry } from "../../store/questionManagement";
import Suspense from "../suspense/Suspense";
import { QuestionType } from "../../common/questionType";
import { connect, ConnectedProps } from "react-redux";

interface DeleteQuestionManagementEntryModalProps extends ConnectedProps<typeof connector> {
    isVisible: boolean;
    onClose: () => void;
    questionManagementEntry: QuestionManagementEntry | null;
}

const DeleteQuestionManagementEntryModal: FC<DeleteQuestionManagementEntryModalProps> = (props) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    
    const { isVisible, onClose, questionManagementEntry } = props;
    const { questionManagementState } = props;
    const { deleteQuestionManagementEntry } = props;
    
    const handleCancelButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onClose();
    }
    
    const handleDeleteButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        setIsSubmitted(true);
        if (questionManagementEntry !== null) {
            deleteQuestionManagementEntry(questionManagementEntry.id);
        }
    }
    
    const handleCloseButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onClose();
        setIsSubmitted(false);
    }
    
    const getMessage = () => {
        let message = "";

        if (questionManagementEntry?.questionType === QuestionType.Raq) {
            message = "Are you sure you want to remove this question from the RAQ section?";
        }
        else if (questionManagementEntry?.questionType === QuestionType.Faq) {
            message = "Are you sure you want to remove this question from the FAQ section?";
        }

        return message;
    }
    
    const getSubmittedMessage = () => {
        return "Question successfully removed.";
    }
    
    const getErrorMessage = () => {
        return "There was a problem removing the question. Please try again.";
    }
    
    return (
        <Modal
            isVisible={isVisible}
        >
            <Suspense isLoading={questionManagementState.isLoading}>
                {!isSubmitted ? (
                    <>
                        <h2>{getMessage()}</h2>

                        <div className="drawer-split">
                            <button className="cancel" onClick={handleCancelButtonClick}>
                                <i className="fa fa-times" />  Cancel
                            </button>
                            <button className="delete" onClick={handleDeleteButtonClick}>
                                <i className="fa fa-trash" />  Remove
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <h2>{questionManagementState.error ? getErrorMessage() : getSubmittedMessage()}</h2>

                        <div className="drawer-split">
                            <button className="cancel" onClick={handleCloseButtonClick}>
                                <i className="fa fa-times" />  Close
                            </button>
                        </div>
                    </>
                )}
            </Suspense>
        </Modal>
    );
}

const mapStateToProps = (state: ApplicationState) => {
    const questionManagementState = state.questionManagement;
    
    return { questionManagementState };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    deleteQuestionManagementEntry: (questionManagementEntryId: number) => {
        dispatch(questionManagementActionCreators.deleteQuestionManagementEntry(questionManagementEntryId));
    }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(DeleteQuestionManagementEntryModal);
import { Action, combineReducers, ReducersMapObject, ThunkDispatch } from "@reduxjs/toolkit";
import { configureStore as createStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { defaultQuestionManagementState, QuestionManagementState } from "./questionManagement";
import { questionManagementReducer } from "../reducers/questionManagementReducer";
import { BannerState, defaultBannerState } from "./banner";
import { bannerReducer } from "../reducers/bannerReducer";
import { AnswerState, defaultAnswerState } from "./answer";
import { answerReducer } from "../reducers/answerReducer";
import { defaultSearchState, SearchState } from "./search";
import { searchReducer } from "../reducers/searchReducer";
import { defaultFeedbackState, FeedbackState } from "./feedback";
import { feedbackReducer } from "../reducers/feedbackReducer";
import { defaultFeedbackTypeState, FeedbackTypeState } from "./feedbackType";
import { feedbackTypeReducer } from "../reducers/feedbackTypeReducer";

export interface ApplicationState {
    answer: AnswerState;
    banner: BannerState;
    feedback: FeedbackState;
    feedbackType: FeedbackTypeState,
    questionManagement: QuestionManagementState;
    search: SearchState;
}

const reducers: ReducersMapObject<ApplicationState> = {
    answer: answerReducer,
    banner: bannerReducer,
    feedback: feedbackReducer,
    feedbackType: feedbackTypeReducer,
    questionManagement: questionManagementReducer,
    search: searchReducer
};

const defaultState: ApplicationState = {
    answer: defaultAnswerState,
    banner: defaultBannerState,
    feedback: defaultFeedbackState,
    feedbackType: defaultFeedbackTypeState,
    questionManagement: defaultQuestionManagementState,
    search: defaultSearchState
}

export const configureStore = () => {
    return createStore<ApplicationState>({
        reducer: combineReducers(reducers),
        middleware: [thunk],
        preloadedState: defaultState,
        devTools: { trace: true }
    })
}

export interface AppThunkAction<TAction, TReturn = Promise<void>> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): TReturn;
}

export type AppThunkDispatch = ThunkDispatch<ApplicationState, {}, Action>;

import { FC } from "react";
import { QuestionManagementEntry } from "../../store/questionManagement";
import QuestionManagementListItem from "./QuestionManagementListItem";
import { useIsAdmin } from "../../hooks/useIsAdmin";

type QuestionManagementListProps = {
    questionManagementEntries: QuestionManagementEntry[];
    onDelete: (questionManagementEntry: QuestionManagementEntry) => void;
};

const QuestionManagementList: FC<QuestionManagementListProps> = (props) => {
    const { questionManagementEntries } = props;
    
    const isAdmin = useIsAdmin();
    
    const handleOnQuestionManagementEntryDelete = (questionManagementEntry: QuestionManagementEntry) => {
        props.onDelete(questionManagementEntry);
    }
    
    return (
        <>
            <ul>
                {questionManagementEntries.map((questionManagementEntry, i) =>
                    <QuestionManagementListItem key={i} questionManagementEntry={questionManagementEntry} allowDeletion={isAdmin} onDelete={handleOnQuestionManagementEntryDelete} />
                )}
            </ul>
        </>
    );
}

export default QuestionManagementList;
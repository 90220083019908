import { SearchResult } from "../../store/search";
import { FC, MouseEventHandler, useEffect, useRef } from "react";

interface SearchSuggestionItemProps {
    searchResult: SearchResult;
    isActive: boolean;
    onClick: () => void;
}

const SearchSuggestionItem: FC<SearchSuggestionItemProps> = (props) => {
    const listItemElementRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
        if (props.isActive) {
            listItemElementRef.current?.scrollIntoView({ block: "nearest" });
        }
    }, [props.isActive]);

    const handleClick: MouseEventHandler<HTMLLIElement> = (event) => {
        props.onClick();
    }

    const className = props.isActive ? "suggestion-active" : "";

    return (
        <li ref={listItemElementRef} role="option" aria-selected={props.isActive} className={className} onClick={handleClick}>
            <span>{props.searchResult.question}</span>
        </li>
    );
}

export default SearchSuggestionItem;
import { ChangeEventHandler, FC, MouseEventHandler, useEffect, useState } from "react";
import Suspense from "../suspense/Suspense";
import { ApplicationState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import { Feedback } from "../../store/feedback";

interface PositiveFeedbackFormSectionProps extends ConnectedProps<typeof connector> {
    onSubmit: (feedback: Pick<Feedback, 'feedbackTypeId' | 'userComments'>) => void;
}

const PositiveFeedbackFormSection: FC<PositiveFeedbackFormSectionProps> = (props) => {
    const [comment, setComment] = useState("");
    const [validationError, setValidationError] = useState<string | null>(null);
    
    const { feedbackState } = props;
    
    useEffect(() => {
        const validate = () => {
            if (comment.length === 0) {
                setValidationError("");
            }
            else if (comment.length > 0 && comment.length < 4) {
                setValidationError("Please enter your comments about why you are experiencing issues.");
            }
            else if (comment.length > 2000) {
                setValidationError("Your comments must be less than 2000 characters.");
            }
            else {
                setValidationError(null);
            }
        }
        
        validate();
    }, [comment, setValidationError]);
    
    const handleCommentChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        setComment(event.currentTarget.value);
    }
    
    const handleSubmitButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        
        if (validationError === null) {
            props.onSubmit({ userComments: comment });
        }
    }
    
    return (
        <>
            <label htmlFor="feedback-comment">
                <span className="thankYou">Thank you for the positive feedback!</span>
                <br />
                <span className="pFeedback">Do you have any additional comments? (optional)</span>
            </label>


            <Suspense isLoading={feedbackState.isLoading} error={feedbackState.error} errorDisplayMessage="There was a problem submitting feedback. Please try again later.">
                <textarea
                    name="comment"
                    className=" feedback-input"
                    id="feedback-comment"
                    placeholder="Enter feedback here."
                    onChange={handleCommentChange}
                    maxLength={2000}
                />

                {validationError && <p className="errorMessage">{validationError}</p>}

                <button type="submit" id="feedback-button" onClick={handleSubmitButtonClick} disabled={validationError !== null}>
                    Submit Feedback
                </button>
            </Suspense>
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => {
    const feedbackState = state.feedback;
    
    return { feedbackState };
}

const connector = connect(mapStateToProps, null);
export default connector(PositiveFeedbackFormSection);
import { AuthenticationContext } from 'react-adal';

export const adalConfig : any = {
    tenant: '152aa3c2-ede6-44a7-b407-977fd5dd3df4',
    clientId: 'fff32d01-2fcd-4d92-9c7e-781d649e6e5b',
    redirectUri: window.location.origin,
    endpoints: {
        api: 'fff32d01-2fcd-4d92-9c7e-781d649e6e5b',
    },
    //Use local storage for TPO partners. WHen new tabs are opened by clicking links
    //to other AskUHM Q&As, local storage will allow the token to be available in the new tab
    cacheLocation: 'localStorage' 
};

const urlParams = new URLSearchParams(window.location.search);

var token : any = '';
if (urlParams.has('token')) {
    token = urlParams.get('token');

    sessionStorage.setItem('externalToken', token);
}
else {
    token = sessionStorage.getItem('externalToken');
}

var email : any = '';
if (urlParams.has('email')) {
    email = urlParams.get('email');

    sessionStorage.setItem('externalEmail', email as string);
}
else {
    email = sessionStorage.getItem('externalEmail');
}

export const externalToken = token;
export const externalEmail = email;

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => externalToken ? externalToken : authContext.getCachedToken(adalConfig.clientId);
export const getUserId = () => externalEmail ? externalEmail : authContext.getCachedUser().userName;

export const isAdmin = () => {
    var user = authContext.getCachedUser();
    if (user != null && user.profile != null && user.profile.roles != null) {
        return user.profile.roles.includes('AskUhmWrite')
    }
    else {
        return false;
    }
};


import { isValidElement, ReactNode } from "react";
import { SpinLoader } from "../loaders/SpinLoader";
import ErrorMessage from "../ErrorMessage";

interface SuspenseWithDataProps<TData> {
    isLoading: boolean;
    fallback?: JSX.Element;
    error?: Error;
    errorDisplayMessage?: string;
    data: TData | undefined;
    children: ReactNode | ReactNode[] | ((data: TData) => ReactNode);
}

const SuspenseWithData = <TData,>({ isLoading, error, errorDisplayMessage, fallback = <SpinLoader />, data, children }: SuspenseWithDataProps<TData>) => {
    if (error !== undefined) {
        return <ErrorMessage error={error} displayMessage={errorDisplayMessage} />;
    }
    if (isLoading || data === undefined) {
        return fallback;
    }

    // If children is of type ReactNode[] or ReactNode
    if (Array.isArray(children) || isValidElement(children)) {
        return <>{children}</>;
    }

    // Otherwise, we know it's a function that returns JSX.Element
    else {
        return (children as (data: TData) => JSX.Element)(data);
    }
}

export default SuspenseWithData;

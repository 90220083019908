import * as ActionTypes from "../common/actionTypes";
import * as LocalStorageHelper from "../common/localStorageHelper";
import * as BannerConstants from "../constants/bannerConstants";

export const defaultBannerState: BannerState = {
    isLoading: false,
    data: LocalStorageHelper.get<Banner>(BannerConstants.bannerCacheKey)
}

// STATE
export interface BannerState {
    data?: Banner;
    isLoading: boolean;
    error?: Error;
}

export interface Banner {
    id: number;
    fileName: string;
    content: string;
    type: string;
}

// ACTIONS
interface RequestReadActiveBanner {
    type: typeof ActionTypes.RequestReadActiveBanner;
}

interface ReceiveReadActiveBanner {
    type: typeof ActionTypes.ReceiveReadActiveBanner;
    data: Banner;
}

interface RequestUpsertActiveBanner {
    type: typeof ActionTypes.RequestUpsertActiveBanner;
    banner: Omit<Banner, "id">
}

interface ReceiveUpsertActiveBanner {
    type: typeof ActionTypes.ReceiveUpsertActiveBanner;
}

interface HandleErrorBanner {
    type: typeof ActionTypes.HandleErrorBanner;
    error: Error;
}

interface ClearErrorBanner {
    type: typeof ActionTypes.ClearErrorBanner;
}

export type KnownAction =
    | RequestReadActiveBanner
    | ReceiveReadActiveBanner
    | RequestUpsertActiveBanner
    | ReceiveUpsertActiveBanner
    | HandleErrorBanner
    | ClearErrorBanner;
import React, { FC, useEffect } from "react";
import Feedback from "../feedback/Feedback";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApplicationState, AppThunkDispatch } from "../../store";
import { answerActionCreators } from "../../actions/answerActions";
import * as StringHelper from "../../common/stringHelper";
import { connect, ConnectedProps } from "react-redux";
import { AnswerContent } from "./AnswerContent";
import { SpinLoader } from "../loaders/SpinLoader";
import Suspense from "../suspense/Suspense";
import AddQuestionManagementEntryButtons from "../questionManagement/AddQuestionManagementEntryButtons";
import { TrackedQueryType } from "../../common/TrackedQueryType";
import { updateNavLink } from "../../services/reportingService";
import { FollowUpEncoding } from "../../common/followUpEncoding";

interface AnswerProps extends ConnectedProps<typeof connector> {
}

const Answer: FC<AnswerProps> = (props) => {
    const { answerState } = props;
    const { readMostRelevantAnswer } = props;

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const query = searchParams.get("query");
    const searchMethod = searchParams.get("searchMethod");
    const capturedSearchText = searchParams.get("capturedSearchText");

    useEffect(() => {
        var searchMethodType = searchMethod as TrackedQueryType;
        const searchMethodValidated = typeof (searchMethod) === "string" && Object.values(TrackedQueryType).includes(searchMethodType) ? searchMethod as TrackedQueryType : undefined;
        const capturedSearchTextValidated = typeof (capturedSearchText) === "string" ? capturedSearchText as string : undefined;

        if (!answerState.isLoading && !StringHelper.isNullOrWhiteSpace(query) && query !== answerState.query) {
            readMostRelevantAnswer(query as string, searchMethodValidated, capturedSearchTextValidated);
        }
    }, [answerState.isLoading, query, answerState.query]);

    if (query === null || query.length === 0) {
        navigate("/");
        return <></>;
    }
    else {
        var navLink = window.location.href;
        updateNavLink(query, navLink);
    }

    const loadingFallback = (
        <div className="content alice-answer">
            <div className="wrap" id="answer">
                <h1>{query}</h1>
                <SpinLoader />
            </div>
        </div>
    );



    return (
        <div className="alice-feedback-answer-page">
            <Suspense isLoading={answerState.isLoading} fallback={loadingFallback} data={answerState.data} error={answerState.error} errorDisplayMessage="There was a problem loading answer. Please try again later.">
                {(answer) => (
                    <>
                        <div className="content alice-answer">

                            <AddQuestionManagementEntryButtons answer={answer} />

                            <div className="wrap" id="answer">
                                <h1>{query}</h1>
                                {query !== answer.question && answer.confidenceScore !== 0 && (
                                    <h6 className="align-center">
                                        <em>We found an answer for "{answer.question}"</em>
                                    </h6>
                                )}

                                <div className="answer">
                                    <div id="answer">
                                        {answer.confidenceScore !== 0 ? (
                                            <AnswerContent markdownBody={answer.body} prompts={answer.prompts} followUp={FollowUpEncoding.DecodeFollowUpPrompt(answer.metadata.follow_up_prompt)} reportingId={answer.reportingId} />
                                        ) : (
                                            "The searched subject is not available in our library."
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Feedback query={query} answer={answer} />
                    </>
                )}
            </Suspense>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState) => {
    const answerState = state.answer;
    return { answerState };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    readMostRelevantAnswer: (query: string, searchMethod?: TrackedQueryType, capturedSearchText?: string) => {
        dispatch(answerActionCreators.readMostRelevantAnswer(query, searchMethod, capturedSearchText));

    }
})

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Answer);

// Answer
export const RequestAnswer = "REQUEST_ANSWER";
export const ReceiveAnswer = "RECEIVE_ANSWER";
export const HandleErrorAnswer = "HANDLE_ERROR_ANSWER";
export const ClearErrorAnswer = "CLEAR_ERROR_ANSWER";
export const ClearAnswer = "CLEAR_ANSWER";

// Banner
export const RequestReadActiveBanner = "REQUEST_READ_ACTIVE_BANNER";
export const ReceiveReadActiveBanner = "RECEIVE_READ_ACTIVE_BANNER";
export const RequestUpsertActiveBanner = "REQUEST_UPSERT_ACTIVE_BANNER";
export const ReceiveUpsertActiveBanner = "RECEIVE_UPSERT_ACTIVE_BANNER";
export const HandleErrorBanner = "HANDLE_ERROR_BANNER";
export const ClearErrorBanner = "CLEAR_ERROR_BANNER";

// Feedback
export const RequestCreateFeedback = "REQUEST_CREATE_FEEDBACK";
export const ReceiveCreateFeedback = "RECEIVE_CREATE_FEEDBACK";
export const RequestUpdateFeedback = "REQUEST_UPDATE_FEEDBACK";
export const ReceiveUpdateFeedback = "RECEIVE_UPDATE_FEEDBACK";
export const HandleErrorFeedback = "HANDLE_ERROR_FEEDBACK";
export const ClearErrorFeedback = "CLEAR_ERROR_FEEDBACK";

// FeedbackType
export const RequestFeedbackTypes = "REQUEST_FEEDBACK_TYPES";
export const ReceiveFeedbackTypes = "RECEIVE_FEEDBACK_TYPES";
export const HandleErrorFeedbackTypes = "HANDLE_ERROR_FEEDBACK_TYPES";
export const ClearErrorFeedbackTypes = "CLEAR_ERROR_FEEDBACK_TYPES";

// QuestionManagement
export const RequestCreateQuestionManagement = "REQUEST_CREATE_QUESTION_MANAGEMENT";
export const ReceiveCreateQuestionManagement = "RECEIVE_CREATE_QUESTION_MANAGEMENT";
export const RequestReadCollectionQuestionManagement = "REQUEST_READ_COLLECTION_QUESTION_MANAGEMENT";
export const ReceiveReadCollectionQuestionManagement = "RECEIVE_READ_COLLECTION_QUESTION_MANAGEMENT";
export const RequestDeleteQuestionManagement = "REQUEST_DELETE_QUESTION_MANAGEMENT";
export const ReceiveDeleteQuestionManagement = "RECEIVE_DELETE_QUESTION_MANAGEMENT";
export const HandleErrorQuestionManagement = "HANDLE_ERROR_QUESTION_MANAGEMENT";
export const ClearErrorQuestionManagement = "CLEAR_ERROR_QUESTION_MANAGEMENT";

// Search
export const RequestSearch = "REQUEST_SEARCH";
export const ReceiveSearch = "RECEIVE_SEARCH";
export const HandleErrorSearch = "HANDLE_ERROR_SEARCH";
export const ClearErrorSearch = "CLEAR_ERROR_SEARCH";
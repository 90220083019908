import React from 'react';
import { FC } from "react";

interface AccessDeniedProps {
}

const AccessDenied: FC<AccessDeniedProps> = () => {
    return (
        <section className="hero access-denied">
            <div className="wrap">
                <h1>
                   You don't have access to Ask UHM.
                </h1>
                <h3>
                    If you are a Broker Partner, please contact your aligned partner advocate for assistance.
                    <br />
                    Otherwise, request access by emailing <a href="mailto:UHUSupport@unionhomemortgage.com">UHUSupport@unionhomemortgage.com</a>.
                </h3>
            </div>
        </section>
    );
}

export default AccessDenied;

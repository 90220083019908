import { ReactNode } from "react";
import { SpinLoader } from "../loaders/SpinLoader";
import SuspenseWithData from "./SuspenseWithData";
import SuspenseWithoutData from "./SuspenseWithoutData";

interface SuspenseProps {
    isLoading: boolean;
    fallback?: JSX.Element;
    error?: Error;
    errorDisplayMessage?: string;
}

interface SuspensePropsWithData<TData> extends SuspenseProps {
    data: TData | undefined;
    children: ReactNode | ReactNode[] | ((data: TData) => JSX.Element);
}

interface SuspensePropsWithoutData extends SuspenseProps {
    children: ReactNode | ReactNode[];
}

const Suspense = <TData,>({ isLoading, error, errorDisplayMessage, fallback = <SpinLoader />, ...props }: SuspensePropsWithData<TData> | SuspensePropsWithoutData) => {
    if ("data" in props) {
        return <SuspenseWithData isLoading={isLoading} error={error} errorDisplayMessage={errorDisplayMessage} fallback={fallback} data={props.data} children={props.children} />;
    }
    else {
        return <SuspenseWithoutData isLoading={isLoading} error={error} errorDisplayMessage={errorDisplayMessage} fallback={fallback} children={props.children} />;
    }
}

export default Suspense;
import { Reducer } from "@reduxjs/toolkit";
import { defaultFeedbackState, FeedbackState, KnownAction } from "../store/feedback";
import * as ActionTypes from "../common/actionTypes";

export const feedbackReducer: Reducer<FeedbackState, KnownAction> = (state, action) => {
    if (state === undefined) {
        return defaultFeedbackState;
    }
    
    switch (action.type) {
        case ActionTypes.RequestCreateFeedback:
        case ActionTypes.RequestUpdateFeedback:
            return {
                ...state,
                isLoading: true
            };
            
        case ActionTypes.ReceiveCreateFeedback:
            return {
                ...state,
                isLoading: false,
                feedback: action.feedback
            };
            
        case ActionTypes.ReceiveUpdateFeedback:
            return {
                ...state,
                isLoading: false
            };
            
        case ActionTypes.HandleErrorFeedback:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
            
        case ActionTypes.ClearErrorFeedback:
            return {
                ...state,
                error: undefined
            };
            
        default:
            return { ...state };
    }
}
import * as ActionTypes from "../common/actionTypes";
import { KnownAction, SearchResult } from "../store/search";
import { AppThunkAction } from "../store";
import * as SearchService from "../services/searchService";

const request = (query: string): KnownAction => {
    return { type: ActionTypes.RequestSearch, query };
}

const receive = (data: SearchResult[]): KnownAction => {
    return { type: ActionTypes.ReceiveSearch, data };
}

const handleError = (error: Error): KnownAction => {
    return { type: ActionTypes.HandleErrorSearch, error };
}

const clear = (): KnownAction => {
    return { type: ActionTypes.ClearErrorSearch };
}

export const searchActionCreators = {
    search: (query: string): AppThunkAction<KnownAction> => async dispatch => {
        dispatch(clear());
        dispatch(request(query));
        
        try {
            const response = await SearchService.search(query);
            if (!response.ok) {
                throw new Error(response.rawBody);
            }
            
            dispatch(receive((response.parsedBody)));
        }
        catch (error) {
            dispatch(handleError(error as Error));
        }
    }
};
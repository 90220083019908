import { adalConfig, authContext, getToken } from "../adalConfig";
import { adalFetch } from "react-adal";

type Parameters = { [key: string]: string };
type Body = any;

type RequestParameters<TParameters extends Parameters> = TParameters | null;
type RequestBody<TBody extends Body> = TBody | null;

type HttpResponse<TResponse> = Response & (
    {
        ok: true,
        parsedBody: TResponse;
    } | {
        ok: false,
        rawBody: string;
    }
);

const getPath = (controller: string, action: string) => {
    return `/api/${controller}/${action}`;
}

const parseUrl = (urlOrPath: string) => {
    let url: URL;
    
    try {
        // Will throw if `urlOrPath` is a relative path
        url = new URL(urlOrPath);
    } catch (e) {
        // Relative paths require that a base is specified. If it is relative, assume the base the the current origin
        url = new URL(urlOrPath, window.location.origin); 
    }
    
    return url;
}

const addQueryParameters = <TParameters extends Parameters>(url: URL, parameters: NonNullable<RequestParameters<TParameters>>) => {
    for (const [key, value] of Object.entries(parameters)) {
        url.searchParams.append(key, value);
    }
    
    return url;
}

async function http<TResponse>(url: URL, args: RequestInit) {
    args.headers = {
        ...args.headers,
        Authorization: `Bearer ${getToken()}`
    }
    const response = await fetch(url.toString(), args) as HttpResponse<TResponse>;

    try {
        if (!response.ok) {
            response.rawBody = await response.text();
        }
        else {
            response.parsedBody = await response.json();
        }
    } catch (error) {
        console.warn(error)
    }

    return response;
}

export async function get<TResponse, TParameters extends Parameters = {}>(controller: string, action: string, parameters: RequestParameters<TParameters> = null, args: RequestInit = {} ) {
    const defaultArgs = { method: "get" };
    
    const path = getPath(controller, action);
    const url = parseUrl(path);
    
    if (parameters !== null) {
        addQueryParameters(url, parameters);
    }
    
    return await http<TResponse>(url, { ...args, ...defaultArgs, headers: { ...args.headers } });
}

export async function post<
    TResponse = undefined,
    TBody extends Body = null,
    TParameters extends Parameters = {}
>(controller: string, action: string, body: RequestBody<TBody> = null, parameters: RequestParameters<TParameters> = null, args: RequestInit = {}) {
    const defaultArgs: RequestInit = { method: "post" };
    
    const path = getPath(controller, action);
    const url = parseUrl(path);
    
    if (parameters !== null) {
        addQueryParameters(url, parameters);
    }
    
    if (body !== null && body !== undefined) {
        defaultArgs.body = JSON.stringify(body);
        defaultArgs.headers = { 'Content-Type': 'application/json' };
    }
    
    return await http<TResponse>(url, { ...args, ...defaultArgs, headers: { ...args.headers, ...defaultArgs.headers } });
}
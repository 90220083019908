import { FC } from "react";

interface ErrorMessageProps {
    displayMessage?: string;
    error: Error;
}

const ErrorMessage: FC<ErrorMessageProps> = (props) => {
    
    const getDisplayMessage = () => {
        const message = props.displayMessage ?? "An unknown error occurred!";
        return message;
    }
    
    return (
        <div className="error">
            <h1>{getDisplayMessage()}</h1>
            <details>
                <summary>Expand for additional information</summary>
                <pre>{props.error.message}</pre>
            </details>
        </div>
    );
}

export default ErrorMessage;
export enum WebActionNames {
    CreateAsync = "CreateAsync",
    DeleteAsync = "DeleteAsync",
    ReadNoParamsAsync = "ReadNoParametersAsync",
    UpdateAsync = "UpdateAsync",

    // Answer
    ReadMostRelevant = "ReadMostRelevant",
    UpdateRecord = "UpdateRecord",
    UpdateNavLink = "UpdateNavLink",

    // Banner
    ReadActive = "ReadActive",
    UpsertActive = "UpsertActive",

    // Search
    Search = "Search"
}
import { ReactNode } from "react";
import { SpinLoader } from "../loaders/SpinLoader";
import ErrorMessage from "../ErrorMessage";

interface SuspenseWithoutDataProps {
    isLoading: boolean;
    fallback?: JSX.Element;
    error?: Error;
    errorDisplayMessage?: string;
    children: ReactNode | ReactNode[];
}

const SuspenseWithoutData = ({ isLoading, error, fallback = <SpinLoader />, children }: SuspenseWithoutDataProps) => {
    if (error !== undefined) {
        return <ErrorMessage error={error} />;
    }

    if (isLoading) {
        return fallback;
    }

    return <>{children}</>;
}

export default SuspenseWithoutData;

import { FeedbackType, KnownAction } from "../store/feedbackType";
import * as ActionTypes from "../common/actionTypes";
import { AppThunkAction } from "../store";
import * as FeedbackTypeService from "../services/feedbackTypeService";

const request = (): KnownAction => {
    return { type: ActionTypes.RequestFeedbackTypes };
}

const receive = (feedbackTypes: FeedbackType[]): KnownAction => {
    return { type: ActionTypes.ReceiveFeedbackTypes, feedbackTypes };
}

const handleError = (error: Error): KnownAction => {
    return { type: ActionTypes.HandleErrorFeedbackTypes, error };
}
    
const clear = (): KnownAction => {
    return { type: ActionTypes.ClearErrorFeedbackTypes };
}

export const feedbackTypeActionCreators = {
    readFeedbackTypes: (): AppThunkAction<KnownAction> => async dispatch => {
        dispatch(clear());
        dispatch(request());

        try {
            const response = await FeedbackTypeService.readCollectionAsync();
            if (!response.ok) {
                throw new Error(response.rawBody);
            }

            dispatch(receive(response.parsedBody));
        } catch (error) {
            dispatch(handleError(error as Error));
        }
    }
}
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { configureStore } from "./store";
import { runWithAdal } from "react-adal";
import { authContext, externalToken } from "./adalConfig";
import { BrowserRouter } from "react-router-dom";
import "uhm-unity-theme-core/dist/unity-theme-core.js";
import "uhm-unity-theme-core/dist/unity-theme-core.min.css";
import "./scss/custom.scss";
import AccessDenied from "./components/AccessDenied";
import * as ServiceWorkerRegistration from "./serviceWorkerRegistration";

const externallogin = externalToken && externalToken !== '';

const loginError = authContext.getLoginError();
const loginFailed = loginError !== undefined && loginError !== null && loginError !== "";
const loginRequired = !loginFailed && !externallogin;

const container = document.getElementById('root')!;
const root = createRoot(container);
const store = configureStore();


ServiceWorkerRegistration.register();

runWithAdal(authContext, () => {
    root.render(
        <>
            {loginFailed ? (
                <AccessDenied />
                
            ) : (
                <Provider store={store}>
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <App />
                    </BrowserRouter>
                </Provider>
            )}
        </>
    )
}, !loginRequired);


import { FC } from "react";

interface SpinLoaderProps {
    message?: string;
}

export const SpinLoader: FC<SpinLoaderProps> = ({ message = "Loading..." }) => {
    return (
        <div className="align-center">
            <div className="loader medium"></div>
            <h2>{message}</h2>
        </div>
    );
}

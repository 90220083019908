import { useEffect, useState } from "react";
import { authContext } from "../adalConfig";

export const useIsAdmin = () => {
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    
    useEffect(() => {
        const user = authContext.getCachedUser();
        if (user != null && user.profile != null && user.profile.roles != null) {
            setIsAdmin(user.profile.roles.includes('AskUhmWrite')); // TODO: Replace with service calls to Uhm.Reference
        }
        else {
            setIsAdmin(false);
        }
    }, []);

    return isAdmin;
}

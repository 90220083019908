import { AppThunkAction } from "../store";
import { KnownAction, QuestionManagementData, QuestionManagementEntry } from "../store/questionManagement";
import * as ActionTypes from "../common/actionTypes";
import * as QuestionManagementService from "../services/questionManagementService";
import { QuestionType } from "../common/questionType";

const requestCreate = (questionManagementEntry: Omit<QuestionManagementEntry, 'id'>): KnownAction => {
    return { type: ActionTypes.RequestCreateQuestionManagement, questionManagementEntry };
}

const receiveCreate = (data: QuestionManagementEntry): KnownAction => {
    return { type: ActionTypes.ReceiveCreateQuestionManagement, data };
}

const requestReadCollection = (): KnownAction => {
    return { type: ActionTypes.RequestReadCollectionQuestionManagement };
};

const receiveReadCollection = (data: QuestionManagementData): KnownAction => {
    return { type: ActionTypes.ReceiveReadCollectionQuestionManagement, data };
}

const requestDelete = (questionManagementEntryId: number): KnownAction => {
    return { type: ActionTypes.RequestDeleteQuestionManagement, questionManagementEntryId };
}

const receiveDelete = (): KnownAction => {
    return { type: ActionTypes.ReceiveDeleteQuestionManagement };
}

const handleError = (error: Error): KnownAction => {
    return { type: ActionTypes.HandleErrorQuestionManagement, error };
}

const clear = (): KnownAction => {
    return { type: ActionTypes.ClearErrorQuestionManagement };
}

export const questionManagementActionCreators = {
    createQuestionManagementEntry: (questionManagementEntry: Omit<QuestionManagementEntry, 'id'>): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch(clear());
        dispatch(requestCreate(questionManagementEntry));
        
        try {
            const response = await QuestionManagementService.createAsync(questionManagementEntry);
            if (!response.ok) {
                throw new Error(response.rawBody);
            }
            
            const createdQuestionManagementEntry: QuestionManagementEntry = {
                ...questionManagementEntry,
                id: response.parsedBody
            };
            
            dispatch(receiveCreate(createdQuestionManagementEntry));
        }
        catch (error) {
            dispatch(handleError(error as Error));
        }
    },
    
    readQuestionManagementData: (): AppThunkAction<KnownAction> => async dispatch => {
        dispatch(clear());
        dispatch(requestReadCollection());
        
        try {
            const response = await QuestionManagementService.readCollectionAsync();
            if (!response.ok) {
                throw new Error(response.rawBody);
            }
            
            const questionManagementEntries = response.parsedBody;
            
            const faqs = questionManagementEntries.filter(e => e.questionType === QuestionType.Faq);
            const raqs = questionManagementEntries.filter(e => e.questionType === QuestionType.Raq);
            
            dispatch(receiveReadCollection({ faqs, raqs }));
        }
        catch (error) {
            dispatch(handleError(error as Error));
        }
    },
    
    deleteQuestionManagementEntry: (questionManagementEntryId: number): AppThunkAction<KnownAction> => async dispatch => {
        dispatch(clear());
        dispatch(requestDelete(questionManagementEntryId));
        
        try {
            const response = await QuestionManagementService.deleteAsync({ id: questionManagementEntryId });
            if (!response.ok) {
                throw new Error(response.rawBody);
            }
            
            dispatch(receiveDelete());
        }
        catch (error) {
            dispatch(handleError(error as Error));
        }
    }
};
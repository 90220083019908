import * as ActionTypes from "../common/actionTypes";

export const defaultSearchState: SearchState = {
    isLoading: false
}

// STATE
export interface SearchState {
    data?: SearchResult[];
    isLoading: boolean;
    error?: Error;
}

export interface SearchResult {
    question: string;
}

// ACTIONS
interface RequestSearch {
    type: typeof ActionTypes.RequestSearch;
    query: string;
}

interface ReceiveSearch {
    type: typeof ActionTypes.ReceiveSearch;
    data: SearchResult[];
}

interface HandleErrorSearch {
    type: typeof ActionTypes.HandleErrorSearch;
    error: Error;
}

interface ClearErrorSearch {
    type: typeof ActionTypes.ClearErrorSearch;
}

export type KnownAction =
    | RequestSearch
    | ReceiveSearch
    | HandleErrorSearch
    | ClearErrorSearch;
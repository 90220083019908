import { FC } from "react";
import Suspense from "../suspense/Suspense";
import { SearchResult } from "../../store/search";
import BounceLoader from "../loaders/BounceLoader";
import SearchSuggestionListEmpty from "./SearchSuggestionListEmpty";
import SearchSuggestionItem from "./SearchSuggestionItem";

interface SearchSuggestionsProps {
    isVisible: boolean;
    suggestions: SearchResult[] | undefined;
    isLoading: boolean;
    error?: Error;
    activeSuggestionIndex?: number;
    onSelect: (suggestion: SearchResult) => void;
}

const loadingFallback = (
    <div className="message-holder">
        <BounceLoader />
    </div>
);

const SearchSuggestions: FC<SearchSuggestionsProps> = (props) => {
    const { isVisible, suggestions, isLoading, error, activeSuggestionIndex } = props;
    
    return (
        <>
            {isVisible && <div className="autocomplete-dropdown-container">
                <Suspense isLoading={isLoading} fallback={loadingFallback} error={error} errorDisplayMessage="There was a problem loading search results." data={suggestions}>
                    {(suggestions) => {
                        if (suggestions.length > 0) {
                            return (
                                <ul id="suggestions-list" className="suggestions" role="listbox">
                                    {suggestions.map((searchResult, index) => {
                                        return <SearchSuggestionItem key={index} isActive={index === activeSuggestionIndex} searchResult={searchResult} onClick={() => props.onSelect(searchResult)} />;
                                    })}
                                </ul>
                            );
                        }
                        else {
                            return <SearchSuggestionListEmpty />;
                        }
                    }}
                </Suspense>
            </div>}
        </>
    );
}

export default SearchSuggestions;
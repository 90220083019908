import { QuestionType } from "./questionType";
import { QuestionManagementData, QuestionManagementEntry } from "../store/questionManagement";
import { questionManagementConstants } from "../constants/questionManagementConstants";

export const questionManagementListExceedsMaximumLength = (questionManagementData: QuestionManagementData, questionType: QuestionType) => {
    const questionManagementItems = getQuestionManagementListByQuestionType(questionManagementData, questionType);
    return questionManagementItems.length >= questionManagementConstants.maximumNumberOfQuestionsPerType;
};

export const questionManagementItemAlreadyExists = (questionManagementData: QuestionManagementData, questionText: string) => {
    const existsInRaqs = questionManagementData.raqs.some((raq) => raq.questionText === questionText);
    const existsInFaqs = questionManagementData.faqs.some((faq) => faq.questionText === questionText);
    
    return existsInRaqs || existsInFaqs;
}

export const getQuestionManagementListByQuestionType = (questionManagementData: QuestionManagementData, questionType: QuestionType) => {
    let questionManagementItems: QuestionManagementEntry[] = [];
    
    if (questionType === QuestionType.Raq) {
        questionManagementItems = questionManagementData.raqs;
    }
    else if (questionType === QuestionType.Faq) {
        questionManagementItems = questionManagementData.faqs;
    }
    
    return questionManagementItems;
}

export const getQuestionTypeName = (questionType: QuestionType) => {
    let questionTypeName = "";
    
    if (questionType === QuestionType.Raq) {
        questionTypeName = "RAQ";
    }
    else if (questionType === QuestionType.Faq) {
        questionTypeName = "FAQ";
    }
    
    return questionTypeName;
}
import { FC, useCallback, useEffect, useState } from "react";
import { ApplicationState, AppThunkDispatch } from "../../store";
import { searchActionCreators } from "../../actions/searchActions";
import { connect, ConnectedProps } from "react-redux";
import SearchSuggestions from "./SearchSuggestions";
import { useNonChildElementClick } from "../../hooks/useNonChildElementClick";
import SearchInput from "./SearchInput";
import { debounce } from "throttle-debounce";
import * as ArrayHelper from "../../common/arrayHelper";
import { useNavigateTo } from "../../hooks/useNavigateTo";
import { SearchResult } from "../../store/search";
import { TrackedQueryType } from "../../common/TrackedQueryType";

interface SearchProps extends ConnectedProps<typeof connector> {
    searchQuery: string;
    setSearchQuery: any;
}

const Search: FC<SearchProps> = (props) => {
    const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number | null>(null);
    
    const navigateTo = useNavigateTo();
    
    const autocompleteDropdownElementRef = useNonChildElementClick<HTMLDivElement>(() => {
        setAreSuggestionsVisible(false);
    });
    
    const { searchState } = props;
    const { search } = props;


    window.onpopstate = () => {
        props.setSearchQuery("");
    }
    
    useEffect(() => {
        setActiveSuggestionIndex(null);
    }, [searchState.data, searchState.isLoading, areSuggestionsVisible]);
    
    const searchDebounced = useCallback(debounce(250, search), []);
    
    const handleSearchInputChange = (value: string) => {
        props.setSearchQuery(value);
        setAreSuggestionsVisible(value.length > 0);

        if (value.length > 0) {
            searchDebounced(value);
        }
    }
    
    const handleSearchInputClear = () => {
        props.setSearchQuery("");
    }
    
    const handleSearchInputFocus = (value: string) => {
        setAreSuggestionsVisible(value.length > 0);
    }
    
    const handleSearchInputSubmit = () => {
        if (props.searchQuery.length > 0) {
            setAreSuggestionsVisible(false);

            let navigationQuery = props.searchQuery;
            if (activeSuggestionIndex !== null && searchState.data) {
                const selectedSuggestion = searchState.data[activeSuggestionIndex];
                if (selectedSuggestion) {
                    props.setSearchQuery(selectedSuggestion.question);
                    navigationQuery = selectedSuggestion.question;
                }
            }

            navigateTo("/answer", { query: navigationQuery, searchMethod: TrackedQueryType.UserInput, capturedSearchText: props.searchQuery });
        }
    }
    
    const handleSearchGoToNextSuggestion = () => {
        if (searchState.data) {
            const nextActiveSuggestionIndex = activeSuggestionIndex !== null ? activeSuggestionIndex + 1 : 0;
            if (ArrayHelper.isValidIndex(nextActiveSuggestionIndex, searchState.data)) {
                setActiveSuggestionIndex(nextActiveSuggestionIndex);
            }
        }
    }
    
    const handleSearchGoToPreviousSuggestion = () => {
        if (searchState.data && activeSuggestionIndex !== null) {
            const previousActiveSuggestionIndex = activeSuggestionIndex - 1;
            if (ArrayHelper.isValidIndex(previousActiveSuggestionIndex, searchState.data)) {
                setActiveSuggestionIndex(previousActiveSuggestionIndex);
            }
            // If we are at the top of the suggestion list, focus the search input instead
            else {
                setActiveSuggestionIndex(null);
            }
        }
    }
    
    const handleSearchSuggestionSelect = (suggestion: SearchResult) => {
        props.setSearchQuery(suggestion.question);
        setAreSuggestionsVisible(false);
        navigateTo("/answer", { query: suggestion.question, searchMethod: TrackedQueryType.DropdownSelection, capturedSearchText: props.searchQuery });
    }
    
    return (
        <div className="col align-center search-bar">
            <div className="autocomplete-order-row">
                <div className="autocomplete-input-and-dropdown" ref={autocompleteDropdownElementRef}>
                    
                    <SearchInput
                        value={props.searchQuery}
                        onChange={handleSearchInputChange} 
                        onSubmit={handleSearchInputSubmit}
                        onClear={handleSearchInputClear}
                        onFocus={handleSearchInputFocus}
                        onGoToNextSuggestion={handleSearchGoToNextSuggestion}
                        onGoToPreviousSuggestion={handleSearchGoToPreviousSuggestion}
                    />
                    
                    <SearchSuggestions
                        isVisible={areSuggestionsVisible}
                        activeSuggestionIndex={activeSuggestionIndex ?? undefined}
                        suggestions={searchState.data}
                        isLoading={searchState.isLoading}
                        error={searchState.error}
                        onSelect={handleSearchSuggestionSelect}
                    />
                    
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState) => {
    const searchState = state.search;
    
    return { searchState };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
    search: (query: string) => {
        dispatch(searchActionCreators.search(query));
    }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Search);
